<template>
  <main class="mb-16 px-4 max-w-sm md:max-w-3xl lg:max-w-6xl mx-auto">
    <h2
      class="my-10 text-2xl font-semibold text-gray-700 dark:text-gray-200 flex"
    >
      <router-link class="w-10 block" tag="a" :to="{ name: 'products' }">
        <svg
          class="h-8 w-8 fill-current text-gray-700 dark:text-gray-200"
          style="enable-background: new 0 0 512 512"
          version="1.1"
          viewBox="0 0 512 512"
          xml:space="preserve"
        >
          <polygon
            points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 "
          />
        </svg>
      </router-link>
      {{ title_page }}
    </h2>

    <form
      @submit.prevent="submit"
      enctype="multipart/form-data"
      class="bg-white rounded-lg shadow-md dark:bg-gray-800 pb-12"
    >
      <hr class="rounded-t-lg mb-4 lg:mb-10 border-g dark:border-gray-600" />
      <div v-if="sizeTypeId != 2">
        <multiInpot v-model="attributes"></multiInpot>
      </div>

      <div
        v-if="sizeTypeId == 2"
        class="max-w-2xl mx-auto pt-8 mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"
      >
        <div class="sm:col-span-3">
          <label for="quantity" class="block text-sm font-medium text-gray-700">
            Quantity
          </label>
          <div class="mt-1">
            <input
              type="number"
              v-model="quantity"
              name="quantity"
              id="quantity"
              autocomplete="given-name"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div class="sm:col-span-3">
          <label for="price" class="block text-sm font-medium text-gray-700">
            Price
          </label>
          <div class="mt-1">
            <input
              type="number"
              v-model="price"
              name="price"
              id="price"
              autocomplete="given-name"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div class="sm:col-span-3">
          <label for="hight" class="block text-sm font-medium text-gray-700">
            Hight
          </label>
          <div class="mt-1">
            <input
              type="number"
              v-model="hight"
              name="hight"
              id="hight"
              autocomplete="given-name"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div class="sm:col-span-3">
          <label for="width" class="block text-sm font-medium text-gray-700">
            Width
          </label>
          <div class="mt-1">
            <input
              type="number"
              v-model="width"
              name="width"
              id="width"
              autocomplete="given-name"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div class="sm:col-span-3">
          <label for="colorId" class="block text-sm font-medium text-gray-700">
            Color
          </label>
          <div class="mt-1">
            <!-- <input type="color" v-model="colorId"> -->
            <select
              v-model="colorId"
              id="colorId"
              name="colorId"
              autocomplete="colorId"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            >
              <option
                v-for="color in colors"
                :value="color.colorId"
                :key="color.colorId"
              >
                {{ color.colorName }}
                <span
                  class="w-8 h-8 rounded-full focus:outline-none"
                  :class="color.hexadecimal"
                ></span>
              </option>
            </select>
          </div>
        </div>
        <div class="sm:col-span-6">
          <label
            for="Product_photo"
            class="block text-sm font-medium text-gray-700 dark:text-grey-H-400"
          >
            Product photo
          </label>
          <label
            class="mt-1 w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-black hover:text-white duration-300"
          >
            <svg
              class="w-8 h-8"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
              />
            </svg>
            <span class="mt-2 text-base leading-normal">Select a file</span>
            <input type="file" class="hidden" @change="selectFile($event)" />
          </label>
        </div>

        <div
          v-if="productImage"
          class="sm:col-span-6 flex justify-center items-center mt-4"
        >
          <img
            :src="productImage"
            alt=""
            class="object-cover w-52 h-52 rounded-xl"
          />
        </div>
      </div>

      <div class="mt-8 mb-10 w-full flex items-center justify-center">
        <button
          class="hover:bg-gray-700 focus:outline-none uppercase w-56 text-centeruppercase py-2 text-xs md:text-sm font-medium leading-5 text-white bg-black border border-transparent rounded-lg transition-colors duration-300"
          @click.prevent="submit"
          id="submit"
          type="submit"
        >
          {{ submit_text }}
        </button>
      </div>
    </form>
    <div v-if="productAttributes.length != 0" class="w-full whitespace-no-wrap">
      <div
        class="flex justify-between items-center pt-4 pb-2 text-xs font-semibold tracking-wide text-left text-gray-500 border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
      >
        <span class="w-2/12 px-4 py-3">Quantity</span>
        <span class="w-2/12 px-4 py-3">Price</span>
        <span v-if="sizeTypeId != 2" class="w-4/12 px-4 py-3">Size</span>
        <span v-if="sizeTypeId == 2" class="w-2/12 px-4 py-3">Height</span>
        <span v-if="sizeTypeId == 2" class="w-2/12 px-4 py-3">Width</span>
        <span class="w-2/12 px-4 py-3">Color</span>
        <span class="w-2/12 px-4 py-3">Action</span>
      </div>
      <div class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
        <div
          :key="index"
          v-for="(productAttribute, index) in productAttributes"
          class="flex justify-between items-center text-gray-700 dark:text-gray-400"
        >
          <span class="w-2/12 px-4 py-3 text-sm">
            <p class="font-semibold" v-if="labelshow == true">
              {{ productAttribute.qty }}
            </p>
            <input
              v-model="Attribute.qty"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              v-if="inpushow == true && index == indxeId"
              type="number"
            />
          </span>
          <span class="w-2/12 px-4 py-3 text-sm">
            <p class="font-semibold" v-if="labelshow == true">
              {{ productAttribute.price }}
            </p>
            <input
              v-model="Attribute.price"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              v-if="inpushow == true && index == indxeId"
              type="number"
            />
          </span>

          <span v-if="sizeTypeId != 2" class="w-4/12 px-4 py-3 text-sm">
            <p class="font-semibold" v-if="labelshow == true">
              {{ productAttribute.size }}
            </p>
            <div v-if="inpushow == true && index == indxeId">
              <select
                v-model="Attribute.sizeId"
                class="mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-32 sm:text-sm border-gray-300 rounded-md"
              >
                <option
                  v-for="(size, index) in sizes"
                  :value="size.sizeId"
                  :key="index"
                >
                  {{ size.sizeName }}
                </option>
              </select>
            </div>
          </span>

          <span v-if="sizeTypeId == 2" class="w-2/12 px-4 py-3 text-sm">
            <p class="font-semibold" v-if="labelshow == true">
              {{ productAttribute.width }}
            </p>
            <input
              v-model="Attribute.width"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              v-if="inpushow == true && index == indxeId"
              type="number"
            />
          </span>

          <span v-if="sizeTypeId == 2" class="w-2/12 px-4 py-3 text-sm">
            <p class="font-semibold" v-if="labelshow == true">
              {{ productAttribute.height }}
            </p>
            <input
              v-model="Attribute.height"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              v-if="inpushow == true && index == indxeId"
              type="number"
            />
          </span>

          <span class="w-2/12 px-4 py-3 text-sm">
            <p class="font-semibold" v-if="labelshow == true">
              {{ productAttribute.color }}
            </p>
            <div v-if="inpushow == true && index == indxeId">
              <select
                v-model="Attribute.colorId"
                id="colorId"
                name="colorId"
                autocomplete="colorId"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              >
                <option
                  v-for="color in colors"
                  :value="color.colorId"
                  :key="color.colorId"
                >
                  {{ color.colorName }}
                  <span
                    class="w-8 h-8 rounded-full focus:outline-none"
                    :class="color.hexadecimal"
                  ></span>
                </option>
              </select>
            </div>
          </span>
          <span class="w-2/12 px-4 py-3 flex justify-center" v-if="labelshow == true">
            <button 
              @click="
                prepareDelete(
                  productAttribute.color,
                  productAttribute.productAttributeId,index
                )
              "
            >
              <svg
                class="w-6 h-6"
                height="512pt"
                viewBox="-57 0 512 512"
                width="512pt"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m156.371094 30.90625h85.570312v14.398438h30.902344v-16.414063c.003906-15.929687-12.949219-28.890625-28.871094-28.890625h-89.632812c-15.921875 0-28.875 12.960938-28.875 28.890625v16.414063h30.90625zm0 0"
                />
                <path
                  d="m344.210938 167.75h-290.109376c-7.949218 0-14.207031 6.78125-13.566406 14.707031l24.253906 299.90625c1.351563 16.742188 15.316407 29.636719 32.09375 29.636719h204.542969c16.777344 0 30.742188-12.894531 32.09375-29.640625l24.253907-299.902344c.644531-7.925781-5.613282-14.707031-13.5625-14.707031zm-219.863282 312.261719c-.324218.019531-.648437.03125-.96875.03125-8.101562 0-14.902344-6.308594-15.40625-14.503907l-15.199218-246.207031c-.523438-8.519531 5.957031-15.851562 14.472656-16.375 8.488281-.515625 15.851562 5.949219 16.375 14.472657l15.195312 246.207031c.527344 8.519531-5.953125 15.847656-14.46875 16.375zm90.433594-15.421875c0 8.53125-6.917969 15.449218-15.453125 15.449218s-15.453125-6.917968-15.453125-15.449218v-246.210938c0-8.535156 6.917969-15.453125 15.453125-15.453125 8.53125 0 15.453125 6.917969 15.453125 15.453125zm90.757812-245.300782-14.511718 246.207032c-.480469 8.210937-7.292969 14.542968-15.410156 14.542968-.304688 0-.613282-.007812-.921876-.023437-8.519531-.503906-15.019531-7.816406-14.515624-16.335937l14.507812-246.210938c.5-8.519531 7.789062-15.019531 16.332031-14.515625 8.519531.5 15.019531 7.816406 14.519531 16.335937zm0 0"
                />
                <path
                  d="m397.648438 120.0625-10.148438-30.421875c-2.675781-8.019531-10.183594-13.429687-18.640625-13.429687h-339.410156c-8.453125 0-15.964844 5.410156-18.636719 13.429687l-10.148438 30.421875c-1.957031 5.867188.589844 11.851562 5.34375 14.835938 1.9375 1.214843 4.230469 1.945312 6.75 1.945312h372.796876c2.519531 0 4.816406-.730469 6.75-1.949219 4.753906-2.984375 7.300781-8.96875 5.34375-14.832031zm0 0"
                />
              </svg>
            </button>
            <button @click="EditattributeInput(productAttribute, index)" class="ml-2.5">
              <svg
                class="w-5 h-5 fill-current"
                height="401pt"
                viewBox="0 -1 401.52289 401"
                width="401pt"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0"
                />
                <path
                  d="m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0"
                />
              </svg>
            </button>
          </span>
           <span v-if="inpushow == true && index == indxeId" class="w-2/12 px-4 py-3 flex justify-center" >
          <button
            @click="closeinput()"
                      >
            <svg
              class="h-5 w-5 fill-current"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="1280.000000pt"
              height="1280.000000pt"
              viewBox="0 0 1280.000000 1280.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <metadata>
                Created by potrace 1.15, written by Peter Selinger 2001-2017
              </metadata>
              <g
                transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
              >
                <path
                  d="M2321 12784 c-122 -33 -105 -17 -1184 -1093 -565 -565 -1041 -1046
-1057 -1070 -94 -140 -103 -331 -23 -471 16 -28 702 -722 1877 -1897 l1851
-1853 -1856 -1857 c-1511 -1512 -1860 -1867 -1878 -1906 -29 -64 -51 -152 -51
-202 0 -59 27 -161 57 -219 39 -74 2085 -2120 2159 -2159 137 -72 291 -74 427
-6 29 14 611 590 1899 1877 l1858 1857 1852 -1851 c1176 -1175 1870 -1861
1898 -1877 149 -86 343 -70 487 38 32 23 513 499 1069 1056 765 768 1017 1026
1037 1065 73 141 74 305 0 434 -16 28 -709 729 -1877 1898 l-1851 1852 1851
1853 c1168 1168 1861 1869 1877 1897 74 129 73 293 0 434 -20 39 -272 297
-1037 1065 -556 557 -1037 1033 -1069 1056 -144 108 -338 124 -487 38 -28 -16
-722 -702 -1898 -1877 l-1852 -1851 -1858 1857 c-1288 1287 -1870 1863 -1899
1877 -100 50 -219 63 -322 35z"
                />
              </g>
            </svg>
          </button>
          <button
             class="ml-2.5"
            @click="EditProductAttributes()"
          >
            <svg
              class="h-5 w-5 fill-current"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 426.667 426.667"
              style="enable-background: new 0 0 426.667 426.667"
              xml:space="preserve"
            >
              <g>
                <g>
                  <path
                    d="M421.876,56.307c-6.548-6.78-17.352-6.968-24.132-0.42c-0.142,0.137-0.282,0.277-0.42,0.42L119.257,334.375
			l-90.334-90.334c-6.78-6.548-17.584-6.36-24.132,0.42c-6.388,6.614-6.388,17.099,0,23.713l102.4,102.4
			c6.665,6.663,17.468,6.663,24.132,0L421.456,80.44C428.236,73.891,428.424,63.087,421.876,56.307z"
                  />
                </g>
              </g>
            </svg>
          </button>         
          </span>
        </div>
      </div>
      <div
        class="flex justify-between items-center px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
      >
        <el-pagination
          background
          :page-size="filter.pageSize"
          layout="prev, pager, next"
          :total="total"
          :current-page.sync="filter.pageNo"
          @current-change="PageChanged"
          class="pagination justify-content-center pagination-sm"
        >
        </el-pagination>
      </div>
    </div>

    <div
      v-else
      id="emptyState"
      class="text-gray_5 text-sm text-center h-96 flex items-center justify-center"
    >
      <div
        v-if="loading"
        id="testtest"
        class="h-64 flex justify-center items-center"
      >
        <svg
          class="w-10 h-w-10 rounded-full"
          viewBox="0 0 38 38"
          xmlns="http://www.w3.org/2000/svg"
          stroke="#fff"
        >
          <g fill="none">
            <g transform="translate(1 1)" stroke-width="2">
              <circle
                stroke="#E1E7EC"
                stroke-opacity=".5"
                cx="18"
                cy="18"
                r="18"
              />
              <path stroke="#AD4BB8" d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      </div>
      <div
        v-else
        class="h-64 flex justify-center items-center w-full bg-white dark:bg-gray-800"
      >
        No Attribute yet..
      </div>
    </div>

    <!-- Start making sure delete it  -->
    <div v-show="makingSureDeleteIt">
      <button
        @click="makingSureDeleteIt = false"
        class="absolute bg-black bg-opacity-60 w-full h-full inset-0 top-0 z-40 flex justify-center items-center cursor-auto"
      >
        <div class="bg-white p-7 w-full max-w-lg rounded-xl cursor-auto z-50">
          <div class="flex justify-between items-start">
            <div class="text-left">
              <p class="text-2xl font-bold">
                Are you sure to delete {{ prepareDeleteCategoryName }} ?
              </p>
              <p class="text-sm mt-2">It will be permanently deleted.</p>
            </div>

            <button
              @click="makingSureDeleteIt = false"
              class="focus:outline-none"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.3941 19.5531L11.999 14.158L6.60398 19.5531C6.00806 20.149 5.04188 20.149 4.44596 19.5531C3.85004 18.9571 3.85004 17.991 4.44596 17.395L9.84101 12L4.44596 6.60496C3.85004 6.00904 3.85004 5.04286 4.44596 4.44694C5.04188 3.85102 6.00806 3.85102 6.60398 4.44694L11.999 9.84198L17.3941 4.44694C17.99 3.85102 18.9562 3.85102 19.5521 4.44694C20.148 5.04286 20.148 6.00904 19.5521 6.60496L14.157 12L19.5521 17.395C20.148 17.991 20.148 18.9571 19.5521 19.5531C18.9562 20.149 17.99 20.149 17.3941 19.5531Z"
                  fill="#676767"
                />
              </svg>
            </button>
          </div>

          <div class="mt-10 space-x-4 flex justify-start">
            <button
              @click="
                deleteButton(
                  prepareDeleteCategoryId,
                  prepareDeleteCategoryIndex
                )
              "
              class="uppercase bg-black text-xs md:text-sm font-medium tracking-widest w-32 py-3 text-surface-white rounded focus:outline-none hover:bg-white hover:text-black border hover:border-black transform duration-700"
            >
              Delete
            </button>
            <button
              @click="makingSureDeleteIt = false"
              class="uppercase bg-white text-xs md:text-sm font-medium tracking-widest w-32 py-3 text-black border border-black rounded focus:outline-none hover:shadow-xl transform duration-700"
            >
              Cancel
            </button>
          </div>
        </div>
      </button>
    </div>
    <!-- End making sure delete it  -->

    <div
      v-if="showAlert"
      class="absolute inset-0 h-full w-full bg-gray-700 bg-opacity-75 flex justify-center items-center"
    >
      <div
        v-if="showAlertLoading"
        class="h-64 flex justify-center items-center"
      >
        <svg class="w-10 h-w-10 rounded-full" viewBox="0 0 38 38" stroke="#fff">
          <g fill="none">
            <g transform="translate(1 1)" stroke-width="2">
              <circle
                stroke="#E1E7EC"
                stroke-opacity=".5"
                cx="18"
                cy="18"
                r="18"
              />
              <path stroke="black" d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      </div>

      <div v-else class="w-1/3 bg-cool-gray-100 rounded">
        <div
          v-if="successed"
          class="bg-white p-7 w-full max-w-lg rounded-xl cursor-auto z-50"
        >
          <div class="flex justify-end">
            <button @click="showAlert = false" class="focus:outline-none">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.3941 19.5531L11.999 14.158L6.60398 19.5531C6.00806 20.149 5.04188 20.149 4.44596 19.5531C3.85004 18.9571 3.85004 17.991 4.44596 17.395L9.84101 12L4.44596 6.60496C3.85004 6.00904 3.85004 5.04286 4.44596 4.44694C5.04188 3.85102 6.00806 3.85102 6.60398 4.44694L11.999 9.84198L17.3941 4.44694C17.99 3.85102 18.9562 3.85102 19.5521 4.44694C20.148 5.04286 20.148 6.00904 19.5521 6.60496L14.157 12L19.5521 17.395C20.148 17.991 20.148 18.9571 19.5521 19.5531C18.9562 20.149 17.99 20.149 17.3941 19.5531Z"
                  fill="#676767"
                />
              </svg>
            </button>
          </div>

          <div class="flex justify-center mt-4">
            <svg
              width="60"
              height="60"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="40" cy="40" r="38" stroke="black" stroke-width="4" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M56.3149 28.7293C57.2486 29.6808 57.225 31.2002 56.2621 32.123L36.2264 51.323C35.2843 52.2257 33.7871 52.2257 32.8451 51.323L23.7379 42.5957C22.775 41.673 22.7514 40.1535 23.6851 39.202C24.6188 38.2504 26.1563 38.227 27.1192 39.1498L34.5357 46.2569L52.8808 28.677C53.8437 27.7543 55.3812 27.7777 56.3149 28.7293Z"
                fill="black"
              />
            </svg>
          </div>

          <div class="text-center mt-8">
            <p class="text-2xl font-bold">{{ addSuccessed }}</p>
            <!-- <p class="text-sm mt-2">Permanently deleted</p> -->
          </div>

          <div class="mt-10 flex justify-center">
            <router-link
              :to="{ name: 'products' }"
              class="bg-black text-center text-xs md:text-sm font-normal tracking-widest w-32 py-3 text-surface-white rounded focus:outline-none hover:bg-white hover:text-black border hover:border-black transform duration-700"
              >Back</router-link
            >
          </div>
        </div>

        <div
          v-else
          class="bg-white p-7 w-full max-w-lg rounded-xl cursor-auto z-50"
        >
          <div class="flex justify-end">
            <button @click="showAlert = false" class="focus:outline-none">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.3941 19.5531L11.999 14.158L6.60398 19.5531C6.00806 20.149 5.04188 20.149 4.44596 19.5531C3.85004 18.9571 3.85004 17.991 4.44596 17.395L9.84101 12L4.44596 6.60496C3.85004 6.00904 3.85004 5.04286 4.44596 4.44694C5.04188 3.85102 6.00806 3.85102 6.60398 4.44694L11.999 9.84198L17.3941 4.44694C17.99 3.85102 18.9562 3.85102 19.5521 4.44694C20.148 5.04286 20.148 6.00904 19.5521 6.60496L14.157 12L19.5521 17.395C20.148 17.991 20.148 18.9571 19.5521 19.5531C18.9562 20.149 17.99 20.149 17.3941 19.5531Z"
                  fill="#676767"
                />
              </svg>
            </button>
          </div>

          <div class="flex justify-center mt-4">
            <svg
              width="60"
              height="60"
              viewBox="0 0 60 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30 58.5C45.7401 58.5 58.5 45.7401 58.5 30C58.5 14.2599 45.7401 1.5 30 1.5C14.2599 1.5 1.5 14.2599 1.5 30C1.5 45.7401 14.2599 58.5 30 58.5Z"
                stroke="black"
                stroke-width="3"
              />
              <path
                d="M38 22L22 38"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22 22L38 38"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <div class="text-center mt-8">
            <p class="text-2xl font-bold">{{ addErorr }}</p>
            <!-- <p class="text-sm mt-2">Permanently deleted</p> -->
          </div>

          <div class="mt-10 flex justify-center">
            <button
              @click="showAlert = false"
              class="bg-black text-center text-xs md:text-sm font-normal tracking-widest w-32 py-3 text-surface-white rounded focus:outline-none hover:bg-white hover:text-black border hover:border-black transform duration-700"
            >
              Try Again
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import multiInpot from "../../../partials/MultiInput.vue";
export default {
  components: {
    multiInpot,
  },

  created() {},
  mounted() {
    // Get by id
    this.productId = this.$route.params.product.productId;
    this.sizeTypeId = this.$route.params.product.sizeTypeId;
    if (this.$route.params.product) {
      this.$http.productsService.GetProductAttributes(this.filter, this.productId)
        .then((res) => {
          this.loading = false;
          this.Successed = true;
          this.data = res.data;
          this.productAttributes = res.data.result.productAttributes;
          this.total = res.data.result.total;
        })
        .catch((err) => {
          setTimeout(() => {
            this.showAlertLoading = false;
            this.successed = false;
          }, 1000);
          this.addErorr = err.response.data.message;
        });
      this.title_page = "Add Attribut";
      this.submit_text = "Add Attributs";
    }
    window.scrollTo(top);
    this.GetColor();
  },
  data() {
    return {
      productId: null,
      showAlert: false,
      loading: false,
      successed: false,
      addErorr: null,
      filter: {
        pageNo: 1,
        pageSize: 5,
      },

      data: [],
      title_page: "",
      choose_emblem: "إختيار صورة",
      submit_text: "",
      file: "",
      sizeId: 2,
      colorId: null,
      quantity: null,
      price: null,
      descripton: null,
      productImage: "",
      productImageName: "",

      colors: [],
      sizes: [],
      productAttributes: [],
      total: 0,
      sizeTypeId: "",
      width: "",
      hight: "",
      attributes: [{ Size: "xs", quantity: "", price: "", colorId: [] }],

      makingSureDeleteIt: false,
      prepareDeleteCategoryName: "",
      prepareDeleteCategoryId: "",
      prepareDeleteCategoryIndex: "",
      showAlertLoading: "",
      inpushow: false,
      labelshow: true,
      Attribute: "",
      indxeId: "",
    };
  },
  methods: {
   
    PageChanged(pageNo) {
      this.filter.pageNo = pageNo;
      this.GetProductAttributes();
    },
    GetProductAttributes() {
      this.$http.productsService
        .GetProductAttributes(this.filter, this.productId)
        .then((res) => {
          this.loading = false;
          this.Successed = true;
          this.addSuccessed = res.data.result.message;
          this.data = res.data;
          this.productAttributes = res.data.result.productAttributes;
          this.total = res.data.result.total;
        })
        .catch((err) => {
          setTimeout(() => {
            this.showAlertLoading = false;
            this.successed = false;
          }, 1000);

          this.addErorr = err.response.data.message;
        });
    },
    reverse_the_value_of_language(x, y) {
      if (x == "language_Arabic") {
        if (y == 1) {
          this.language_Arabic = 0;
        } else {
          this.language_Arabic = 1;
        }
      }
      if (x == "language_English") {
        if (y == 1) {
          this.language_English = 0;
        } else {
          this.language_English = 1;
        }
      }

      if (x == "language_Others") {
        if (y == 1) {
          this.language_Others = 0;
        } else {
          this.language_Others = 1;
        }
      }
    },
    selectFile(input) {
      this.productImageName = input.target.files[0].name;
      let reader = new FileReader();
      reader.onload = (event) => {
        this.productImage = event.target.result;
      };
      reader.readAsDataURL(input.target.files[0]);
    },
    submit() {
      this.showAlert = true;
      this.showAlertLoading = true;
      if (this.sizeTypeId != 2) {
        var newAttributes = {
          productId: Number(this.$route.params.product.productId),
          attributes: this.attributes,
        };
        this.$http.productsService
          .multiProductAttributes(newAttributes)
          .then((res) => {
            setTimeout(() => {
              this.showAlertLoading = false;
              this.successed = true;
            }, 1000);

            this.addSuccessed = res.data.result.message;
            var addedAttributes = res.data.result.addedAttributes;
            var that = this;
            addedAttributes.forEach(function (addedAttribute) {
              that.productAttributes.unshift({
                productAttributeId: addedAttribute.productAttributeId,
                size: addedAttribute.size,
                qty: addedAttribute.qty,
                price: addedAttribute.price,
                color: addedAttribute.color,
                status: addedAttribute.status,
              });
            });
            this.attributes = [
              { Size: "xs", quantity: "0", price: "0", colorId: [] },
            ];
          })
          .catch((err) => {
            setTimeout(() => {
              this.showAlertLoading = false;
              this.successed = false;
            }, 1000);

            this.addErorr = err.response.data.message;
          });
      } else {
        var newAttribut = {
          productId: Number(this.$route.params.product.productId),
          colorId: this.colorId,
          qty: Number(this.quantity),
          price: Number(this.price),
          width: Number(this.width),
          height: Number(this.hight),
          productImage: this.productImage,
          productImageName: this.productImageName,
        };
        this.$http.productsService
          .AddProductAttributes(newAttribut)
          .then((res) => {
            setTimeout(() => {
              this.showAlertLoading = false;
              this.successed = true;
            }, 1000);

            this.addSuccessed = res.data.result.message;
            this.productAttributes.unshift({
              productAttributeId:
                res.data.result.addedAttributes.productAttributeId,
              size: res.data.result.addedAttributes.size,
              height: res.data.result.addedAttributes.height,
              width: res.data.result.addedAttributes.width,
              qty: res.data.result.addedAttributes.qty,
              price: res.data.result.addedAttributes.price,
              color: res.data.result.addedAttributes.color,
              status: res.data.result.addedAttributes.status,
            });
          })
          .catch((err) => {
            setTimeout(() => {
              this.showAlertLoading = false;
              this.successed = false;
            }, 1000);
            this.addErorr = err.response.data.message;
          });
      }
    },
    EditProductAttributes() {
      this.showAlert = true;
      this.loading = true;
      var updateAttributes = {
        productAttributeId: this.Attribute.productAttributeId,
        sizeId: this.Attribute.sizeId,
        colorId: this.Attribute.colorId,
        qty: Number(this.Attribute.qty),
        price: Number(this.Attribute.price),
        Width: Number(this.Attribute.width),
        Height: Number(this.Attribute.height),
       };
      this.$http.productsService
        .EditProductAttributes(updateAttributes)
        .then((res) => {
          this.loading = false;
          this.Successed = true;
          this.GetProductAttributes()
          this.closeinput()
            this.addSuccessed = res.data.result.message;
        })
        .catch((err) => {
          setTimeout(() => {
            this.showAlertLoading = false;
            this.successed = false;
          }, 1000);

            this.addErorr = err.response.data.message;
        });
    },

    prepareDelete(categoryName, categoryId, index) {
      this.prepareDeleteCategoryName = categoryName;
      this.prepareDeleteCategoryId = categoryId;
      this.prepareDeleteCategoryIndex = index;
      this.makingSureDeleteIt = true;
    },

    deleteButton(productAttributeId, index) {
      this.showAlert = true;
      this.showAlertLoading = true;

      this.$http.productsService
        .DeleteProductAttributes(productAttributeId)
        .then((res) => {
          this.addSuccessed = res.data.result.message;
          this.productAttributes.splice(index, 1);

          setTimeout(() => {
            this.showAlertLoading = false;
            this.successed = true;
          }, 1000);
        })
        .catch((err) => {
          setTimeout(() => {
            this.showAlertLoading = false;
            this.successed = false;
            this.erorrMessage = err.response.data.message;
          }, 1000);
        });
    },
    GetColor() {
      this.$http.lookupService
        .GetColors()
        .then((res) => {
          this.data = res.data;
          this.colors = res.data.result.colors;
        })
        .catch((err) => {
          this.addErorr = err.response.data.message;
        });
    },
    EditattributeInput(Attribute, index) {
      this.indxeId = index;
      this.Attribute = Attribute;
      this.GetSize();
      this.inpushow = true;
      this.labelshow = false;
    },
    closeinput() {
      this.inpushow = false;
      this.labelshow = true;
    },
    GetSize() {
      this.$http.productsService
        .GetSizeList(this.sizeTypeId)
        .then((res) => {
          this.data = res.data;
          this.sizes = res.data.result.sizesList;
        })
        .catch((err) => {
          this.addErorr = err;
        });
    },
  },
};
</script>

<style>
.test {
  background-color: #035388;
  color: white;
}
.input.invalid label {
  color: red;
}
.input.invalid input {
  border: 1px solid red;
  background-color: #ffc9aa;
}

.submit button[disabled],
.submit button[disabled]:hover,
.submit button[disabled]:active {
  border: 1px solid #ccc;
  background-color: transparent;
  color: #ccc;
  cursor: not-allowed;
}
</style>
