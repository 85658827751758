<template type="text/x-template">
  <div>
    <div
      :key="index"
      v-for="(item, index) in values"
      class="max-w-3xl mx-auto mt-6 flex items-center justify-between"
    >
      <div class="">
        <label
          for="Size"
          class="block text-sm font-medium text-gray-700 dark:text-grey-H-200"
        >
          Size
        </label>
        <select
          @input="updateValue()"
          v-model="item.Size"
          class="mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-32 sm:text-sm border-gray-300 rounded-md"
        >
          <option
            v-for="(size, index) in sizes"
            :value="size.sizeId"
            :key="index"
          >
            {{ size.sizeName }}
          </option>
        </select>
      </div>
      <div class="">
        <label
          for="colorId"
          class="block text-sm font-medium text-gray-700 dark:text-grey-H-200"
        >
          Color
        </label>
        <select
           @input="updateValue()"
          v-model="item.colorId"
          id="colorId"
          name="colorId"
          class="mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-32 sm:text-sm border-gray-300 rounded-md"
        >
          <option
            v-for="color in colors"
            :value="color.colorId"
            :key="color.colorId"
          >
            {{ color.colorName }}
          </option>
        </select>
      </div>
      <div class="">
        <label
          for="quantity"
          class="block text-sm font-medium text-gray-700 dark:text-grey-H-200"
        >
          quantity
        </label>
        <input
          type="number"
          v-model="item.quantity"
          @input="updateValue()"
          class="mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-32 sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <div class="">
        <label
          for="price"
          class="block text-sm font-medium text-gray-700 dark:text-grey-H-200"
        >
          price
        </label>
        <input
          type="text"
          v-model="item.price"
          @input="updateValue()"
          class="mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-32 sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <div class="flex justify-center items-center">

          <label class="mt-6 w-16 h-10 flex items-center justify-center  bg-white text-blue rounded-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-black hover:text-white duration-300">
              <svg class="w-6 h-6"
                   fill="currentColor"
                   xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 20 20">
                  <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
              </svg>

              <input type="file"
                     style="display: none"
                     ref="fileInput"
                     accept="image/*"
                     @change="onFilePicked($event,index)" />
          </label>
          <div v-if="item.image"
               class=" flex justify-center items-center">
              <img :src="item.image"
                   alt=""
                   class="object-cover w-12 h-12 rounded-xl" />
          </div>

      </div> 
      <div class=" flex justify-center items-center ">
        <button type="button" @click="deleteValue(index)" class="focus:outline-none">
          <svg
            class="fill-current stroke-2 stroke-current dark:text-white"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.5396 6.89624L6.53955 18.8962"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.53955 6.89624L18.5396 18.8962"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
    <div class="text-center mt-16 flex justify-center items-center">
      <button type="button" @click="addValue()" class="focus:outline-none">
        <svg
          class="stroke-current stroke-2 fill-current dark:text-white"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 8V16"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8 12H16"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value"],

  created() {
    var v = Array.from(this.value);
    this.values = v;
    this.GetColor();
    this.GetSize();
    
    
  },
  data() {
    return {
      values: [{colorId:[],}],
      colors: [],
      sizes: [],
      sizeTypeId: 1,
      imageUrl:null,
      productImageName:null,

     };
  },
  methods: {
    GetSize() {
      this.$http.productsService
        .GetSizeList(this.sizeTypeId)
        .then((res) => {
          this.data = res.data;
          this.sizes = res.data.result.sizesList;
        })
        .catch((err) => {
          this.addErorr = err;
        });
    },
    GetColor() {
      this.$http.lookupService
        .GetColors()
        .then((res) => {
          this.data = res.data;
          this.colors = res.data.result.colors;
        })
        .catch((err) => {
          this.addErorr = err;
        });
    },
    updateValue() {
      this.$emit("input", this.values);
    },
    updateValueImage(imageUrl,index){
        this.values[index].image = imageUrl;
        this.values[index].productImageName = this.productImageName;
        this.$emit("input", this.values);
        this.imageUrl='';
        this.productImageName=null;


    },
    deleteValue(index) {
      this.values.splice(index, 1);
      this.$emit("input", this.values);
    },
    addValue() {
      // console.log("{{add}}", this.values);
      this.values.push({});
      this.$emit("input", this.values);
    },
   
      onFilePicked(input, index) {
      this.productImageName = input.target.files[0].name
      let reader = new FileReader();
      reader.onload = (event) => {
      this.imageUrl = event.target.result;
      this.updateValueImage(this.imageUrl,index)

      };
      reader.readAsDataURL(input.target.files[0]);

    },

  },
};
</script>
